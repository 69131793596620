<template>
  <div>
    <vs-table stripe :data="time_period_details">
      <template slot="header">
        <vs-row>
          <vs-col vs-type="flex" vs-w="6">
            <vs-button color="primary" icon="arrow_back" @click="$router.push({name:'StudentConnect'})" size="small"></vs-button> &nbsp;
            <h3 style="margin-bottom: 10px;">
              {{ capitalize(time_period_type) }} [{{evaluation_count}}] | {{ startDate }} - {{ endDate }}
            </h3>
          </vs-col>

        </vs-row>
      </template>
      <template slot="thead">
        <vs-th>
          Can Id
        </vs-th>
        <vs-th>
          Candidate Name
        </vs-th>
        <vs-th>
          Spoc Name
        </vs-th>
        <vs-th>
          Level
        </vs-th>
        <vs-th>
          Email
        </vs-th>
        <vs-th>
          Course
        </vs-th>
        <vs-th>
          Spoc Name
        </vs-th>
        <vs-th>
          Enrollment Date
        </vs-th>
        <vs-th>
          Action
        </vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].identity">
            {{ data[indextr].identity }}
          </vs-td>

          <vs-td :data="data[indextr].person_name">
            {{ data[indextr].person_name }}
          </vs-td>
           <vs-td :data="data[indextr].spoc_name">
            {{ data[indextr].spoc_name }}
          </vs-td>
          <vs-td :data="data[indextr].level">
            {{ data[indextr].level }}
          </vs-td>
          <vs-td :data="data[indextr].email">
            {{ data[indextr].email }}
          </vs-td>
          <vs-td :data="data[indextr].courses">
            {{ data[indextr].courses }}
          </vs-td>
          <vs-td :data="data[indextr].spoc_name">
            {{ data[indextr].spoc_name }}
          </vs-td>
          <vs-td :data="data[indextr].enrollment_date">
            {{ data[indextr].enrollment_date }}
          </vs-td>
          <vs-td :data="data[indextr].identity">
            <div @click="openPopupEmit(data[indextr])">
              <vs-icon icon="info_outline" size="small" color="dark"></vs-icon>
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination style="padding-top: 1%;" :total="total_pages" v-model="page_no"></vs-pagination>
  </div>
</template>

<script>
import moment from 'moment';
// import axios from "axios";
// import constants from "../../../constants.json";
import ApiServices from "../../ApiServices";
export default {
  mounted() {
    this.time_period_type = this.$route.params.time_period_detail.evaluation_type;
    this.evaluation_count = this.$route.params.time_period_detail.evaluation_count;
    this.startDate = this.$route.params.time_period_detail.start_date;
    this.endDate = this.$route.params.time_period_detail.end_data;
    this.time_period_header = this.$route.params.time_period_detail.evaluation_header
    this.getTimePeriodEvaluationDetails(this.page_no)
    console.log("Get All Details",  this.startDate, this.endDate)
  },
  watch: {
    page_no(value) {
      this.getTimePeriodEvaluationDetails(value)
    },
  },
  data: () => ({
    time_period_header: '',
    startDate: '',
    evaluation_count:'',
    endDate: '',
    time_period_type: '',
    time_period_details: [],
    page_no: 1,
    total_pages: 0,
    
  }),
  methods: {
    capitalize(val) {
            const str = val.replace(/_/g, " ");
            const arr = str.split(" ");
            for (var i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

            }
            const str2 = arr.join(" ");
            return str2

        },
    getTimePeriodEvaluationDetails(page) {
      this.$vs.loading();
      //  let url = `${constants.MILES_DASHBOARDS}timeperiodEvaluationDetails?evaluation_type=${this.time_period_type}&from_date=${this.startDate}&to_date=${this.endDate}&page=${page}`;
      // axios
      //   .get(url, {
      //     headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
      //   })
        ApiServices.timeperiodEvaluationDetails(this.time_period_type,this.startDate,this.endDate,page)
        .then((response) => {
          console.log("evaluationDetails", response.data)
          this.time_period_details = []
           var date = null;
          for (let i = 0; i < response.data.data.length; i++) {
            if (response.data.data[i].enrollment_date !== undefined) {
              date = moment
                .unix(response.data.data[i].enrollment_date)
                .format("DD-MMM-YYYY");
            } else {
              date = 0;
            }
            var obj = {
              identity: response.data.data[i].identity,
              person_name: response.data.data[i].person_name,
              level: response.data.data[i].level,
              courses: response.data.data[i].courses,
              email: response.data.data[i].email,
              enrollment_date: date,
              spoc_name: response.data.data[i].spoc_name
            };
            this.time_period_details.push(obj);
          }
          this.total_pages = response.data.last_page;
          // this.total_pages = response.data.last_page;
          this.$vs.loading.close();
          console.log("getEvaluationDoneDetails", this.time_period_details)
        })
        .catch((error) => {
          console.log(error);
          this.$vs.loading.close();
        });
    },
    openPopupEmit(payload) {
      console.log("payload", payload);
      this.openBigPopup(payload.mwb_id);
    }
  }
}
</script>